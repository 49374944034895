<template>
    <div id="home">
        <div class="right top-links">
            <a href="/">
                <img src="../assets/img/logo.png" alt="AT" class="logo">
            </a>
        </div>
        <div class="frow">
            <!-- <div class="pic">
                <div class="cropper">
                    <img src="../assets/img/me.jpg" alt="">
                </div>
            </div> -->
            <div class="title">
                <h1>Arnaud Triolet</h1>
                <p class="h5">Développeur web freelance, Marseille, France</p>
            </div>
        </div>
        <div class="mt-5">
            <p>Développement de projet de site vitrine ou e-commerce et intégration de votre design ou de votre charte graphique.</p>
            <p>Étude et suivis de votre projet d'application, de CRM ou encore d'API sur mesure.</p>
            <p>Création de votre application mobile connecté a votre site ou votre API.</p>
        </div>
        <div class="mt-5">
            <div class="row">
                <div class="col-md-12">
                    <h3 class='mb-4'>Mes compétences</h3>
                </div>
                <div class="col-md-3">
                    <ul class='skills'>
                        <li>
                            <font-awesome-icon :icon="['fab', 'linux']" /> Linux
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'docker']" /> Docker
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'github']" /> Github
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'bitbucket']" /> Bitbucket
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'raspberry-pi']" /> Raspberry Pi
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <ul class='skills'>
                        <li>
                            <font-awesome-icon :icon="['fab', 'php']" /> PHP
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'node-js']" /> Node
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'sass']" /> Sass
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'python']" /> Python
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fas', 'terminal']" /> Bash
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'markdown']" /> Markdown
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <ul class='skills'>
                        <li>
                            <font-awesome-icon :icon="['fab', 'laravel']" /> Laravel
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'symfony']" /> Symfony
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'vuejs']" /> Vue
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'magento']" /> Magento
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'wordpress']" /> Wordpress
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'bootstrap']" /> Bootstrap
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'stripe']" /> Stripe
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <ul class='skills'>
                        <li>
                            <font-awesome-icon :icon="['fas', 'phone-alt']" /> Téléphone
                        </li>
                        <li>
                            <font-awesome-icon :icon="['far', 'envelope']" /> Mail
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'trello']" /> Trello
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'telegram']" /> Telegram
                        </li>
                        <li>
                            <font-awesome-icon :icon="['fab', 'slack']" /> Slack
                        </li>
                    </ul>
                </div>
            </div> 
        </div>
        <div class="mt-5 text-center">
            <router-link to="/contact" class='btn btn-light px-4 py-2'>
                <font-awesome-icon :icon="['far', 'envelope']" /> Contactez moi
            </router-link>
            <a href="https://join.slack.com/t/arnaudtrioletgroupe/shared_invite/zt-jfegnn7r-p2F_rEoPl1CP~cQy3_SLsw" target="_blank" class='btn btn-primary px-4 py-2 ml-2'>
                <font-awesome-icon :icon="['fab', 'slack']" /> Rejoignez moi sur Slack
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        name: "HomeComponent"
    }
</script>
<style>
    p {
        font-size:1.3em;
    }
    .frow .pic {
        max-width: 200px;
    }

    .frow .title {
        /* padding-left: 50px; */
        /* padding-top: 30px; */
    }

    .cropper  img{
        width: 100%;
    }
    .cropper {
        width: 200px;
        height: 200px;
        border-radius: 100px;
        overflow: hidden;
        border: 3px solid white;
        box-shadow: rgba(255,255,255,0.2) 0 0 10px 10px;
    }

    ul {
        columns: 3;
    }

    ul.skills {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    ul.skills li {
        list-style: none;
        display: block;
        margin-bottom: 10px;
        cursor: default;
    }
    
    ul.skills li:hover svg,
    ul.skills li:hover svg *,
    ul.skills li:hover {
        color:#ce8703;
    }

    ul.skills li svg {
        height: 30px;
        width: 30px!important;
        margin-right: 10px;
        vertical-align: bottom;
    }
</style>