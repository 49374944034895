import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowCircleLeft, faTerminal, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope, faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons"
import { 
    faLinux, faDocker, faGitAlt,
    faWordpress, faMagento, faNpm, 
    faLaravel, faVuejs, faTrello,
    faTelegram, faSlack, faSymfony,
    faRaspberryPi, faPhp, faNodeJs,
    faSass, faPython, faStripe,
    faBitbucket, faGithub, faBootstrap,
    faMarkdown
  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faLinux, faWordpress, faMagento, 
  faNpm, faLaravel, faVuejs, 
  faDocker, faGitAlt, faEnvelope,
  faTrello, faTelegram, faSlack, 
  faSymfony, faRaspberryPi, faArrowCircleLeft,
  faEnvelopeOpen, faPhp, faNodeJs, faSass,
  faPython, faStripe, faBitbucket, faGithub,
  faBootstrap, faMarkdown, faTerminal,
  faPhoneAlt
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(VueRouter)

// const Foo = Home
// const Bar = { template: '<div>bar</div>' }

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

